import React from 'react'
import { Flex, Menu } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import vars, { privateRoutes } from 'config/vars'
import { isMobile } from 'helpers/utility'
import { Logo } from 'components/micro/Common'
import { toggleSidebar } from 'redux/slices/themeSlice'

function Sidenav() {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()

  const { user } = useSelector(state => state.auth)

  const closeNav = () => {
    if (!isMobile()) return
    dispatch(toggleSidebar())
  }

  return (
    <div className="flex h-screen flex-col">
      <div className="h-16 bg-[--secondary-color]">
        <div className="flex h-full w-full items-center justify-center">
          <Logo width={180} rowProps={{ className: '' }} light />
        </div>
      </div>

      <div className="flex-1 overflow-y-scroll pt-3">
        <Flex vertical justify="space-between" className="h-full">
          <Menu
            theme="light"
            mode="inline"
            className=""
            selectedKeys={[location.pathname]}
            items={Object.keys({ ...privateRoutes })
              .filter(key => {
                const route = privateRoutes[key]
                if (typeof route.sidenav === 'function') {
                  return route.sidenav(user)
                }
                return route.sidenav !== false
              })
              .map(key => {
                const route = privateRoutes[key]
                const icon = route.icon ? <route.icon style={{ fontSize: route.iconSize || 22 }} /> : null

                return {
                  key: route.to,
                  icon,
                  label: route.label,
                  className: `font-semibold text-[#939393]`,
                  onClick: () => {
                    closeNav()
                    navigate(route.to)
                  }
                }
              })}
          />

          <div className="m-1 p-6">
            <Flex vertical>
              <div className="">
                <a
                  href={vars.eula_url}
                  target="_blank"
                  rel="noreferrer"
                  className="font-semibold underline-offset-2 hover:underline"
                >
                  End User License Agreement
                </a>
              </div>

              <div className="my-1 pt-4">
                <a
                  href={vars.privacy_policy_url}
                  target="_blank"
                  rel="noreferrer"
                  className="font-semibold underline-offset-2 hover:underline"
                >
                  Privacy Policy
                </a>
              </div>
            </Flex>
          </div>
        </Flex>
      </div>
    </div>
  )
}

export default Sidenav
